<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/wechatBind/list"
    :filterFields="filterFields"
    :columns="columns"
    :operateButtonList="operateButtonList"
    :defaultSortBy="[]"
    :operationWidth="100")
  DetailDialog(
    v-model="showDialog"
    :data="rowData"
  )
</template>

<script>
import { mapActions } from 'vuex'
import DetailDialog from './components/DetailDialog.vue'

export default {
  name: 'UserManage',
  components: { DetailDialog },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'delete', url: '/wechatManage/userManage/delete' },
      { name: 'detail', url: '/wechatManage/userManage/detail' }
    ])
    return {
      showTable: false,
      showDialog: false,
      filterFields: [{
        name: 'usewaterCode',
        label: '用水户号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerName',
        label: '用户名称',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'name',
        label: '微信名称',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'usewaterAddress',
        label: '用水地址',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'bindTime',
        label: '绑定时间',
        form: {
          tag: 'date',
          dateType: 'daterange'
        }
      }],
      columns: [
        { name: 'openId', label: 'ID', width: 120 },
        { name: 'name', label: '微信名称', width: 100 },
        { name: 'phone', label: '联系电话', width: 120 },
        { name: 'usewaterCode', label: '用水户号', width: 100 },
        {
          name: 'customerName',
          label: '用户',
          minWidth: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'usewaterAddress', label: '用水地址', minWidth: 150 },
        { name: 'idType', label: '证件类型', width: 100, relation: 'idType', render: { type: 'select' } },
        { name: 'idNum', label: '证件号码', width: 150 },
        { name: 'bindTime', label: '绑定时间', minWidth: 120, render: { type: 'date' } }
      ],
      operateButtonList: [{
        label: '解绑',
        name: 'delete',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.delete,
        func: this.handleDelete
      }, {
        label: '详情',
        name: 'info',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.detail,
        func: ({ data }) => {
          this.rowData = data
          this.showDialog = true
        }
      }],
      rowData: {}
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['idType'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    async handleDelete ({ data }) {
      try {
        await this.$confirm('是否解绑？', '提示')
        await this.$httpDelete({
          url: `/wechatBind/delete/${data.id}`
        })
        this.$message.success('解绑成功')
        this.getData()
        return 'success'
      } catch (error) {
        return error
      }
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.drawerForm
  padding: 8px
</style>
