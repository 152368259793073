<template lang="pug">
el-dialog(
  title="详情"
  append-to-body
  width="70%"
  :visible.sync="visible")
  .form-box(v-if="showForm")
    DataForm.full-box(
      ref="form"
      v-model="data"
      type="grid"
      :gridspanNum="3"
      gridFlow="row"
      inlineGrid
      readonly
      :formFields="formFields")
  ButtonGroup(
    slot="footer"
    :buttonList="buttonList")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'

export default {
  name: 'DetailDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      showForm: false,
      formFields: [
        { name: 'openId', label: 'ID', form: { tag: 'text' } },
        { name: 'name', label: '姓名', form: { tag: 'text' } },
        { name: 'phone', label: '联系电话', form: { tag: 'text' } },
        { name: 'usewaterCode', label: '用水户号', form: { tag: 'text' } },
        { name: 'customerName', label: '用户名称', form: { tag: 'text', colSpan: 2 } },
        { name: 'usewaterAddress', label: '用水地址', form: { tag: 'text', colSpan: 3 } },
        {
          name: 'idType',
          label: '证件类型',
          relation: 'idType',
          render: { type: 'select' },
          form: { tag: 'text' }
        },
        { name: 'idNum', label: '证件号码', form: { tag: 'text' } },
        { name: 'bindTime', label: '绑定时间', form: { tag: 'text' }, render: { type: 'date' } },
        {
          name: 'bindAttchs',
          label: '证件附件',
          render: { type: 'file', showType: 'image', colSpan: 3 },
          form: { tag: 'text', colSpan: 3 }
        }
      ],
      buttonList: [{
        name: 'close',
        label: '关闭',
        func: () => {
          this.$emit('input', false)
        }
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['idType'])
        .then(res => {
          renderRelationColumns(res, [this.formFields])
          this.showForm = true
        })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.form-box
  display: flex
  // justify-content: center
  width: 100%
  max-height: 500px
</style>
